<template>
  <div class="JobHunting">
    <logged-heard></logged-heard>
    <!-- 主题区域内容 -->
    <div class="center space-between">
      <tabbar-model :typeId="1" :typeIdOne="2" :typeIndex="8"></tabbar-model>
      <div class="one current-JobHunting">
        <div class="job-tabbar text-main font-normal row-center">
          <div
            v-for="(item, index) in jobTab"
            :key="index"
            class="pointer"
            :class="[
              jobIndex == 0 && jobIndex == index ? 'jonClassOne' : '',
              jobIndex == 1 && jobIndex == index ? 'jonClassTwo' : '',
              (jobIndex == 2 || jobIndex == 3) && jobIndex == index
                ? 'jonClassThree'
                : '',
              tabbarIndex == index ? 'tabClass' : '',
            ]"
            @click="jobTabClick(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="job-tab-center">
          <div v-if="jobNo" class="job-tab-yes">
            <div v-if="tabbarIndex == 3 || tabbarIndex == 1 || tabbarIndex == 0">
              <div class="job-item" v-for="item in posiList" :key="item.id">
                <div
                  class="job-item-heard space-between"
                  @click="navTo('/Position-details', item.position_id,item.pos_state)"
                  v-if="item"
                >
                  <div class="job-left" v-if="item.position">
                    <!-- 投递成功 已面约 在线沟通中-->
                    <div class="text-blue font-lighter font-weight-bolder left-text">
                      {{ item.position.position_name }}【{{
                        item.position.province
                      }}】<span v-if="item.interview">
                        <span v-if="item.interview.status == 0">待接受</span>
                        <span v-if="item.interview.status == 1">已接受</span>
                        <span v-if="item.interview.status == 2">已拒绝</span>
                        <span v-if="item.interview.status == 3">已面试</span>
                        <span v-if="item.interview.status == 4">已过期 </span>
                        <span v-if="item.interview.status == 5">已下线</span>
               
                      </span>
                    </div>
                    <div class="row-center left-text-one">
                      <div v-if="item.position.salary_min == '面议'">
                        {{ item.position.salary_min }}
                      </div>
                      <div v-else>
                        {{ item.position.salary_min }}-{{ item.position.salary_max }}
                      </div>
                      <div>{{ item.position.experience }}</div>
                      <div>{{ item.position.education }}</div>
                    </div>
                  </div>
                  <div class="row-center job-right" v-if="item.ent">
                    <div class="right-left">
                      <div class="text-blue font-lighter font-weight-bolder">
                        {{ item.ent.ent_name }}
                      </div>
                      <div class="row-center font-normal right-left-text">
                        <div>{{ item.ent.other_info.nature.text }}</div>
                        <div>{{ item.ent.other_info.scale.text }}</div>
                      </div>
                    </div>
                    <img :src="item.ent.logo_url" alt="" />
                  </div>
                </div>
                <div class="job-item-bottom space-between font-normal" v-if="item.ent">
                  <div class="bottom-left-text row-center">
                    <div
                      v-for="(itemone, index) in item.ent.welfare.slice(0, 8)"
                      :key="index"
                    >
                      {{ itemone }}
                      <span v-if="index == 7">...</span>
                    </div>
                  </div>
                  <div
                    class="bg-blue text-white flex-center button-bottom pointer"
                    @click="
                      sendClick(
                        item.pos_state,
                        item.hr.user_id,
                        item.position_id,
                        item.ent.ent_name,
                        item.position.enterprise_id,
                        item.ent.logo_url,
                        item.hr.user_name,
                        item.position.position_name,
                        item.hr.id
                      )
                    "
                    v-if="tabbarIndex == 0"
                  >
                    继续沟通
                  </div>
                  <div
                    class="bg-blue text-white flex-center button-bottom pointer"
                    @click="
                      sendClick(
                        item.pos_state,
                        item.hr.user_id,
                        item.position_id,
                        item.ent.ent_name,
                        item.position.enterprise_id,
                        item.ent.logo_url,
                        item.hr.user_name,
                        item.position.position_name,
                        item.hr.id,
                        item.is_connect
                      )
                    "
                    v-if="tabbarIndex==1"
                  >
                    {{item.is_connect==0 ? '立即沟通' : '继续沟通'}}
                  </div>
                  <div
                    class="bg-blue text-white flex-center button-bottom pointer"
                    v-if="tabbarIndex == 3"
                    @click="cancelClick(item.position_id,item.pos_state)"
                  >
                    取消收藏
                  </div>
                </div>
              </div>
              <!-- 没有数据的状态 -->
              <div class="job-tab-no direction font-normal" v-if="total == 0">
                <img src="../../assets/forget/13.png" alt="" />
                <div>没有记录，好可怕~~~你需要多多沟通</div>
              </div>
            </div>
            <!-- 面试内容 -->
            <div v-if="tabbarIndex == 2">
              <div class="job-item-two" v-for="item in posiList" :key="item.id">
                <div class="job-two-heard space-between" v-if="item.ent">
                  <div class="teo-heard-left row-center">
                    <img :src="item.ent.logo_url" alt="" />
                    <div class="heard-left-text">
                      <div class="two-heatrd row-center">
                        <div class="text-blue font-lighter font-weight-bolder">
                          {{ item.ent.ent_name }}
                        </div>
                        <div>{{ item.ent.other_info.nature.text }}</div>
                        <div>{{ item.ent.other_info.scale.text }}</div>
                      </div>
                      <div class="two-bototm">{{ item.address }}</div>
                    </div>
                  </div>
                  <div class="teo-heard-right" v-if="item.position">
                    <div class="row-center right-heatrd-text">
                      <div class="text-blue font-weight-bolder font-lighter">
                        {{ item.position.position_name }}【{{ item.position.province }}】
                      </div>
                      <span
                        class="font-lighter"
                        v-if="item.position.salary_min == '面议'"
                        >{{ item.position.salary_min }}</span
                      >
                      <span class="font-lighter" v-else
                        >{{ item.position.salary_min }}-{{
                          item.position.salary_max
                        }}</span
                      >
                    </div>
                    <div class="right-bototm-text row-center">
                      <div>{{ item.position.experience }}</div>
                      <div>{{ item.position.education }}</div>
                    </div>
                  </div>
                </div>
                <div class="job-two-bototm space-between font-normal" v-if="item.ent">
                  <div class="two-bottom-left row-center">
                    <div
                      :class="item.status == 1 || item.status == 3 ? 'color-class' : ''"
                    >
                      <span v-if="item.status == 0">待接受 </span>
                      <span v-if="item.status == 1">已接受</span>
                      <span v-if="item.status == 2">已拒绝</span>
                      <span v-if="item.status == 3">已完成</span>
                      <span v-if="item.status == 4">已过期</span>
                      <span v-if="item.status == 5">已下线</span>
                    </div>
                    <div>{{ item.interview_time }}</div>
                  </div>
                  <div
                    class="two-bottom-right text-white bg-blue flex-center pointer"
                    @click="
                      sendClick(
                        item.pos_state,
                        item.hr.user_id,
                        item.position_id,
                        item.ent.ent_name,
                        item.position.enterprise_id,
                        item.ent.logo_url,
                        item.hr.user_name,
                        item.position.position_name,
                        item.hr.id,
                        item.is_connect
                      )
                    "
                  >
                    {{item.is_connect==0 ? '立即沟通' : '继续沟通'}}
                  </div>
                </div>
              </div>
              <!-- 没有数据的状态 -->
              <div class="job-tab-no direction font-normal" v-if="total == 0">
                <img src="../../assets/forget/13.png" alt="" />
                <div>没有记录，好可怕~~~你需要多多投递</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 最右侧的logo -->
      <div class="JobHunting-logon direction font-normal text-main" v-if="kfObj">
        <img src="../../assets/forget/2.jpg" alt="" />
        <div>关注干纺织微信服务号，重要信息不再错过！</div>
      </div>
    </div>
    <feet-model></feet-model>
  </div>
</template>

<script>
import TIM from "tim-js-sdk";
import loggedHeard from "../../components/loggedHeard.vue";
import feetModel from "../../components/feet.vue";
import tabbarModel from "../../components/tabbar.vue";
import api from "../../api/position";
import { mapGetters, mapState } from "vuex";
export default {
  components: { loggedHeard, feetModel, tabbarModel },
  computed: {
    ...mapGetters(["hasLogin"]),
    ...mapState(["userDetail"]),
  },
  data() {
    return {
      jobTab: [
        {
          id: 0,
          name: "在线沟通",
        },
        {
          id: 1,
          name: "已投递",
        },
        {
          id: 2,
          name: "面试",
        },
        {
          id: 3,
          name: "收藏",
        },
      ],
      jobIndex: 0,
      tabbarIndex: 0,
      jobNo: true,
      kfObj: null,
      posiList: [],
      total: 0,
    };
  },

  created() {
    if (this.userDetail.user_type == 2) {
      this.toggleClick();
      return;
    }
    this.kfObj = JSON.parse(localStorage.getItem("kfObj"));
    let id = this.$route.query.id;
    if (id == 4 || !id) {
      this.tabbarIndex = 0;
      this.jobIndex = 0;
      this.getConnect();
    } else if (id == 1) {
      this.tabbarIndex = 1;
      this.jobIndex = 1;
      this.getDeliverList();
    } else if (id == 2) {
      this.tabbarIndex = 2;
      this.jobIndex = 2;
      this.getInterviewList();
    } else if (id == 3) {
      this.tabbarIndex = 3;
      this.jobIndex = 3;
      this.myCollection();
    }
  },

  mounted() {},

  methods: {
    toggleClick() {
      this.$emit("toggleClick");
    },
    cancelClick(id,pos_state ) {
      if(pos_state==0){
        this.$util.msg('职位已下线','warning')
        return
      }
      api.collectPosi({ id }).then((res) => {
        if (res.code == 200) {
          this.$util.msg(res.msg);
          this.myCollection();
        }
      });
    },
    // 继续沟通
    sendClick(pos_state,id, posiId, entName, ent_id, ent_logo, contacts, jop, hr_id,is_connect) {
      if(pos_state==0){
        this.$util.msg('职位已下线','warning')
        return
      }
      if (!this.hasLogin) {
        this.$util.msg("请先登录", "warning");
        return;
      }
      if (this.tabbarIndex == 0 || is_connect==1) {
        this.$router.push({ path: "/interaction", query: { id, posiId } });
        return;
      }
      if (!this.userDetail.detail || this.userDetail.detail.length == 0) {
        this.$util.msg("请先完善简历", "warning");
        return;
      } else {
        if (this.userDetail.detail.completeness < 90) {
          this.$util.msg("你的简历完善度小于90%，请继续完善简历", "warning");
          return;
        }
      }
      if (this.userDetail.id == hr_id) return;
      if (this.userDetail.user_type !== 1) {
        this.$util.msg("你还未切换为求职者类型，请切换", "warning");
        return;
      }
      let query = {
        user_id: this.userDetail.id, //用户id
        user: this.userDetail.name, //用户名称
        head: this.userDetail.avatar_url, //用户头像
        ent_name: entName, //企业名称
        ent_id, //企业id
        ent_logo, //企业logo
        contacts, //企业hr名字
        jopId: posiId, //工作id
        jop, //职位名称
        customStatus: 1, //沟通状态
        id: hr_id, //hr id
      };
      let message = this.$tim.createCustomMessage({
        to: id.toString(),
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          data: "text",
          description: "打扰了,希望和你聊聊这个职位，是否方便呢",
          extension: JSON.stringify(query),
        },
      });
      // 2. 发送消息
      let promise = this.$tim.sendMessage(message);
      promise
        .then((imResponse) => {
          this.$router.push({ path: "/interaction", query: { id, posiId } });
        })
        .catch(function (imError) {
          // 发送失败
          console.warn("sendMessage error:", imError);
        });
    },
    jobTabClick(index) {
      if (index == this.jobIndex) return;
      this.jobIndex = index;
      this.tabbarIndex = index;
      if (index == 3) {
        this.myCollection();
      } else if (index == 0) {
        this.getConnect();
      } else if (index == 1) {
        this.getDeliverList();
      } else {
        this.getInterviewList();
      }
    },
    // 面试列表
    getInterviewList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      api.getInterviewList().then((res) => {
        this.posiList = res.data.data;
        this.total = res.data.total;
        loading.close();
      });
    },
    // 已投递的职位列表
    getDeliverList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      api.getDeliverList().then((res) => {
        this.posiList = res.data.data;
        this.total = res.data.total;
        loading.close();
      });
    },
    // 获取沟通中的职位
    getConnect() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      api.getConnect().then((res) => {
        this.posiList = res.data.data;
        this.total = res.data.total;
        loading.close();
      });
    },
    // 获取收藏职位列表
    myCollection() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      api.myCollection().then((res) => {
        if (res.code == 200) {
          this.posiList = res.data.data;
          this.total = res.data.total;
          loading.close();
        }
      });
    },
    navTo(path, id,pos_state) {
      if(pos_state==0){
        this.$util.msg('职位已下线','warning')
        return
      }
      if (id) {
        this.$router.push({ path, query: { id } });
        return;
      }
      this.$router.push({ path });
    },
  },
};
</script>
<style lang="less" scoped>
.JobHunting {
  width: 100%;
  min-height: 100vh;
  background: #f6f7f8;
  .center {
    width: 100%;
    padding: 20px 11.875rem 2.6875rem 11.875rem;
    align-items: flex-start;
    .current-JobHunting {
      margin: 0 1.25rem;
      width: 100%;
      .job-tabbar {
        width: 100%;
        height: 51px;
        background: #ffffff;
        box-shadow: 0px 1px 0px 0px #f3f3f3;
        padding-left: 14px;
        div {
          margin-right: 30px;
          line-height: 3.1875rem;
          position: relative;
          &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-bottom: 3px solid #126bf9;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            transition: 0.3s;
          }
        }
        .tabClass {
          &:after {
            width: 4.375rem;
          }
        }
        .jonClassOne {
          &:after {
            width: 4.375rem;
          }
        }
        .jonClassTwo {
          &:after {
            width: 2.625rem;
          }
        }
        .jonClassThree {
          &:after {
            width: 1.75rem;
          }
        }
      }
      .job-tab-center {
        width: 100%;
        margin-top: 10px;
        .job-tab-no {
          width: 100%;
          height: 797px;
          background: #ffffff;
          justify-content: center;
          color: #9fa3b0;
          img {
            width: 113px;
            padding: 0 1.875rem;
            margin-bottom: 100px;
          }
        }
        .job-tab-yes {
          width: 100%;
          .job-item {
            margin-bottom: 10px;
            background: #ffffff;
            padding: 0 30px;
            .job-item-heard {
              width: 100%;
              height: 90px;
              border-bottom: 1px solid #eee;
              .job-left {
                font-size: 15px;
                .left-text {
                  span {
                    color: #898e9d;
                  }
                }
                .left-text-one {
                  color: #61687c;
                  margin-top: 10px;
                  div:first-child {
                    color: #fd7240;
                  }
                  div:nth-child(2) {
                    margin: 0 12px;
                  }
                }
              }
              .job-right {
                color: #61687c;

                .right-left {
                  margin-right: 155px;
                  .right-left-text {
                    margin-top: 10px;
                    div:last-child {
                      margin-left: 13px;
                    }
                  }
                }
                img {
                  width: 56px;
                  height: 56px;
                  border-radius: 3px;
                }
              }
            }
            .job-item-bottom {
              width: 100%;
              height: 50px;
              color: #61687c;
              .bottom-left-text {
                div {
                  margin-right: 14px;
                }
              }
              .button-bottom {
                width: 85px;
                height: 23px;
                border-radius: 4px;
              }
            }
          }
          .job-item:last-child {
            margin-bottom: 0;
          }
          .job-item-two {
            width: 100%;
            background: #ffffff;
            padding: 0 30px;
            margin-bottom: 10px;
            .job-two-heard {
              width: 100%;
              border-bottom: 1px solid #eee;
              height: 90px;
              color: #61687c;
              font-size: 15px;
              .teo-heard-left {
                img {
                  width: 56px;
                  height: 56px;
                  border-radius: 3px;
                }
                .heard-left-text {
                  margin-left: 11px;
                  .two-heatrd {
                    div:nth-child(2) {
                      margin: 0 10px;
                    }
                  }
                  .two-bototm {
                    margin-top: 8px;
                  }
                }
              }
              .teo-heard-right {
                padding-right: 121px;
                .right-heatrd-text {
                  div {
                    margin-right: 6px;
                  }
                  span {
                    color: #fd7240;
                  }
                }
                .right-bototm-text {
                  margin-top: 8px;
                  div:last-child {
                    margin-left: 10px;
                  }
                }
              }
            }
            .job-two-bototm {
              width: 100%;
              height: 50px;
              color: #61687c;
              .two-bottom-left {
                div:first-child {
                  color: #f81d1d;
                  margin-right: 20px;
                }
                .color-class {
                  color: #67c23a !important;
                }
              }
              .two-bottom-right {
                width: 85px;
                height: 23px;
                border-radius: 4px;
              }
            }
          }
          .job-item-two:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .JobHunting-logon {
      width: 317px;
      height: 181px;
      background: #ffffff;
      justify-content: space-evenly;
      text-align: center;
      padding: 0 60px;
      img {
        width: 60px;
        height: 60px;
        border-radius: 6px;
      }
    }
  }
}
</style>
